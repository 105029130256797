import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import profilePhoto from "../images/profile.jpg";

function AboutPage() {
  return (
    <Layout>
      <SEO keywords={[`mobile`, `angular`, `ionic`, `graphql`]} title="About" />

      <section className="flex flex-col md:flex-row items-start text-lg">
        <img className="h-40 w-40 rounded-full mx-auto" src={profilePhoto} />
        <div className="md:w-2/3 md:mr-8">
          <h1 className="text-2xl md:text-3xl mb-6 font-bold">
            A long time ago, in a galaxy far, far away, I started my career as a
            software engineer... 🦕
          </h1>
          <p>
            OK, maybe it wasn't that long ago, but it sure feels like it! 😄
          </p>
          <p>
            👋🏽 My name is Ashteya Biharisingh, currently based in Cork, Ireland.
            ☘️
          </p>
          <p>
            I've been a developer for close to 20 years and over the past 6
            years I've focused mostly on front-end development, specifically
            with Angular and Ionic.
          </p>
          <p>
            <strong>
              I love learning new things and explaining what I learn through
              this blog. 🤫 Don't tell anyone, but I'm currently experimenting
              with React and Gatsby. 👩🏻‍🔬
            </strong>
          </p>
          <p>
            My tutorials are focused on making sure you can get started quickly
            with your own project.
          </p>
          <p>
            I believe you'll make more progress if you just{" "}
            <strong>get started and learn as you go</strong> instead of wasting
            days trying to learn everything upfront. 🚀
          </p>
          <p>
            I sincerely hope you find my tutorials useful. If you have any
            questions you can find me on twitter{" "}
            <a
              className="border-yellow-400 border-b-2"
              href="https://twitter.com/ashteya"
            >
              @ashteya
            </a>
            . My DMs are open! <a href="https://twitter.com/ashteya">✉️</a>
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage;
